import React, { FC } from 'react'
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

export type CourseOverviewTileProps = {
  title: string
  description: string
  id: string
  image?: IGatsbyImageData
}
const CourseOverviewTile: FC<CourseOverviewTileProps> = ({ title, description, image }) => (
  <div className={`w-full md:w-3/4 xl:w-1/2 lg:p-4 flex flex-row mx-auto`}>
    <div className="w-1/3 xl:w-1/4 mb-4 mx-auto pr-4 lg:pr-8">
      <GatsbyImage image={getImage(image)} alt={title} />
    </div>
    <div className="w-2/3 flex flex-col">
      <div className="mb-2 font-bold text-xl lg:text-2xl font-display leading-relaxed text-left">
        {title}
      </div>
      <div className="mb-2 text-left text-lg lg:text-xl max-w-screen-sm mx-auto font-body font-light leading-relaxed">
        {description}
      </div>
    </div>
  </div>
)

export default CourseOverviewTile
