import React, { FC } from 'react'
import GreenTick from '../svg/greenTick.svg'

export type CheckTileProps = {
  title: string
  description: string
  id: string
  width?: number
}
const CheckTile: FC<CheckTileProps> = ({ title, description }) => (
  <div className={`w-full md:w-3/4 xl:w-1/3 lg:p-4 flex flex-row mx-auto`}>
    <div className="mb-4 mx-auto pr-2 pt-1">
      <GreenTick />
    </div>
    <div className="flex flex-col">
      <div className="mb-2 font-bold text-xl lg:text-2xl font-display leading-relaxed text-left">
        {title}
      </div>
      <div className="mb-2 text-left text-lg lg:text-xl max-w-screen-sm mx-auto font-body font-light leading-relaxed">
        {description}
      </div>
    </div>
  </div>
)

export default CheckTile
