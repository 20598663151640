import AnchorLink from 'react-anchor-link-smooth-scroll'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '../components/Button'
import Layout from '../components/layout/Layout'
import TrainingHero from '../svg/Training1.svg'
import LocationIcon from '../svg/location_on_black_48dp.svg'
import PaidIcon from '../svg/paid_black_48dp.svg'
import TimeIcon from '../svg/hourglass_empty_black_48dp.svg'
import GradientText from '../components/GradientText'
import FullWidthCaptionedTitle from '../components/FullWidthCaptionedTitle'
import CourseOverviewTile, { CourseOverviewTileProps } from '../components/CourseOverviewTile'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import CheckTile, { CheckTileProps } from '../components/CheckTile'
import React, { useState } from 'react'
import RobotInput from '../components/inputs/RobotInput/RobotInput'
import TextInput, { defaultValidate, validateEmail } from '../components/inputs/TextInput/TextInput'
import TextareaInput from '../components/inputs/TextareaInput/TextareaInput'
import SEO from '../components/SEO'
import Modal from '../components/Modal'
import RadioInput from '../components/inputs/RadioInput/RadioInput'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const iconsConfig = [
  {
    id: 'time',
    text: '15 hours',
    icon: <TimeIcon />
  },
  {
    id: 'cost',
    text: 'AUD $900',
    icon: <PaidIcon />
  },
  {
    id: 'location',
    text: 'Live - Online, Onsite - on request',
    icon: <LocationIcon />
  }
]

const attendeesConfig: CheckTileProps[] = [
  {
    id: 'teachers',
    title: 'Teachers',
    description:
      'Are you looking for new interesting ways of teaching that will help your students thrive?'
  },
  {
    id: 'business',
    description: 'Would like to create a supportive learning environment for your courses?',
    title: 'Business facilitators'
  },
  {
    id: 'interested',
    description:
      'Are you interested in the education field and currently looking for inspiration?',
    title: 'Curious People'
  }
]

const courseOverviewConfig: CourseOverviewTileProps[] = [
  {
    id: 'overview',
    title: 'eduScrum Overview',
    description: 'Discover eduScrum framework and its values, roles, events & artifacts.'
  },
  {
    id: 'experience',
    description: 'Gain experience in practice and learn how to implement eduScrum in your project.',
    title: 'Practical experience'
  },
  {
    id: 'education',
    description:
      'Learn how to develop an Agile mindset and create a supporting environment for your students.',
    title: 'Agile in Education'
  },
  {
    id: 'century',
    description: 'Find out how to help students develop 21st century skills  with emphasis on 4Cs.',
    title: '21st Century Skills'
  }
]

const modalData = {
  success: {
    title: 'Thank you for filling the form',
    text: 'We will be in touch with you shortly!'
  },
  fail: {
    title: 'Problem submitting',
    text: 'Please try again later'
  }
}

const defaultFormState = {
  'form-name': 'register',
  message: '',
  'last-name': '',
  'first-name': '',
  email: '',
  tel: '',
  time: ''
}

const defaultValidState = {
  'first-name': true,
  'last-name': true,
  email: true,
  tel: true,
  message: true,
  time: true
}
const Index = () => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query OverviewImagesQuery {
      century: file(relativePath: { eq: "overview21.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      education: file(relativePath: { eq: "overviewKnowledge.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      experience: file(relativePath: { eq: "overviewTransform.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      overview: file(relativePath: { eq: "overviewHistory.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const configWithImages = courseOverviewConfig.map((z: CourseOverviewTileProps) => ({
    ...z,
    image: images[z.id]
  }))
  const [open, setOpen] = useState(false)
  const [modalD, setModalD] = useState({ ...modalData.success })
  const [fromValidState, setFormValidState] = useState<Record<string, boolean>>({
    ...defaultValidState
  })
  const [formState, setFormState] = useState<Record<string, string>>({
    'form-name': 'register',
    ...defaultFormState
  })
  const updateStateValue = (name: string, value: string) => {
    setFormValidState({ ...fromValidState, [name]: true })
    setFormState({ ...formState, [name]: value })
  }
  return (
    <>
      <Modal open={open} setOpen={setOpen} {...modalD} />
      <Layout>
        <SEO
          title="eduScrum Basic Training"
          description="Interactive, activity-based course where you can gain knowledge about eduScrum framework and learn how you can apply it in your work."
        />
        <section className="py-20">
          <div className="container mx-auto px-8 flex flex-col-reverse lg:flex-row">
            <div className="text-center lg:text-left lg:w-2/3 2xl:w-1/2 pt-10 xl:pt-20 2xl:pt-30">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-relaxed font-display mb-6">
                <GradientText>eduScrum</GradientText> Basic Training
              </h1>
              <p className="text-lg lg:text-xl font-body font-light leading-relaxed mb-6">
                An interactive, activity-based course where you gain knowledge about eduScrum framework
                and learn how you can apply it in your work.
              </p>
              <p className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-center xl:justify-start">
                <AnchorLink href="#register">
                  <Button size="lg" className="mb-4 md:mr-8">
                    Register for training
                  </Button>
                </AnchorLink>
                <AnchorLink href="#course-overview">
                  <Button secondary size="lg">
                    Learn more
                  </Button>
                </AnchorLink>
              </p>
            </div>
            <div className="lg:w-3/4">
              <TrainingHero />
            </div>
          </div>
        </section>
        <section className="mx-auto py-20 bg-brandBlue-bg">
          <div className="container mx-auto flex flex-row flex-wrap justify-around">
            {iconsConfig.map((x) => (
              <div key={x.id} className="p-4 flex flex-col w-48">
                <div className="mx-auto pb-4">{x.icon}</div>
                <div className="text-center font-display text-2xl font-bold">{x.text}</div>
              </div>
            ))}
          </div>
        </section>
        <FullWidthCaptionedTitle
          id="course-overview"
          title="What will you learn?"
          caption="Course Overview"
          text="We will provide you with basic knowledge about the eduScrum framework."
        />
        <section className="container mx-auto px-4 lg:px-20 xl:px-40 pt-10 pb-20 text-center flex flex-col lg:flex-row flex-wrap justify-around">
          {configWithImages.map((x) => (
            <CourseOverviewTile key={x.id} {...x} />
          ))}
        </section>
        <FullWidthCaptionedTitle
          id="attendees"
          title="Who is the training for?"
          caption="Attendees"
          text="Are you wondering if this is the training for you? Let's check it out."
        />
        <section className="container mx-auto px-4 lg:px-20 xl:px-40 pt-10 pb-20 text-center flex flex-col lg:flex-row flex-wrap justify-around">
          {attendeesConfig.map((x) => (
            <CheckTile key={x.id} {...x} />
          ))}
        </section>
        <div id="register-form" />
        <FullWidthCaptionedTitle
          id="register"
          title="Are you interested?"
          caption="Register"
          text="Would you like to become part of the eduScrum Basic Training?"
        />
        <section className="container mx-auto py-10 text-center">
          <form
            className="mx-auto w-full max-w-4xl text-left"
            name="register"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="register" />
            <RobotInput onChange={updateStateValue} />
            <div className="flex flex-wrap mb-6">
              <TextInput
                id="first-name"
                value={formState['first-name']}
                label="First name"
                onChange={updateStateValue}
                isValid={fromValidState['first-name']}
              />
              <TextInput
                id="last-name"
                value={formState['last-name']}
                label="Last name"
                onChange={updateStateValue}
                isValid={fromValidState['last-name']}
              />
            </div>
            <div className="flex flex-wrap mb-6">
              <TextInput
                id="email"
                value={formState['email']}
                label="Email address"
                onChange={updateStateValue}
                type="email"
                isValid={fromValidState.email}
                errorMessage="Has to be a valid email address"
              />
              <TextInput
                id="tel"
                value={formState['tel']}
                label="Phone number"
                onChange={updateStateValue}
                type="tel"
                isValid={fromValidState.tel}
              />
            </div>
            <div className="flex flex-wrap  mb-6">
              <RadioInput
                label="Select course time"
                value={formState.time}
                onChange={updateStateValue}
                isValid={fromValidState.time}
                id="courseTime"
              />
            </div>
            <div className="flex flex-wrap mb-6">
              <TextareaInput
                id="message"
                value={formState['message']}
                label="Message"
                onChange={updateStateValue}
                isValid={fromValidState.message}
              />
              <div className="mx-3 mt-4">
                <ReCAPTCHA
                  sitekey="6LempfEcAAAAABqYrNuHnt3iywAW-Po9rRGNwV8L"
                  onChange={(value: string) => {
                    updateStateValue('g-recaptcha-response', value)
                  }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="m-4 w-90 md:w-1/3 cursor-pointer">
                <input
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    let isValid = true
                    const newValidState = { ...fromValidState }
                    Object.keys(defaultValidState).forEach(async (current: string) => {
                      const isValueValid = defaultValidate(formState[current])
                      newValidState[current] = isValueValid
                      isValid = isValid && isValueValid
                    })
                    const isEmailValid = validateEmail(formState.email)
                    newValidState.email = isEmailValid
                    isValid = isValid && isEmailValid
                    setFormValidState(newValidState)
                    if (!isValid) return
                    fetch('/', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                      body: encode({ 'form-name': 'register', ...formState })
                    })
                      .then((res) => {
                        if (res.status !== 200) {
                          throw new Error('Problem submitting')
                        }
                        setModalD({ ...modalData.success })
                        setOpen(true)
                        setFormState({ ...defaultFormState })
                      })
                      .catch(() => {
                        setModalD({ ...modalData.fail })
                        setOpen(true)
                      })
                  }}
                  className={`w-full p-4  cursor-pointer
                bg-gradient-to-br from-primary-lighter to-primary-darker
              hover:bg-primary-darker
                rounded-full
              text-white
                font-semibold
                font-body `}
                />
              </div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  )
}

export default Index
