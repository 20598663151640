import { FC, useState } from 'react'

type RadioInputProps = {
  label: string
  value: string
  onChange: (name: string, value: string) => void
  id: string
  className?: string
  type?: string
  isValid?: boolean
  errorMessage?: string
}

const RadioInput: FC<RadioInputProps> = ({
  label,
  onChange,
  id,
  value,
  className = '',
  type = 'text',
  isValid = true,
  errorMessage = 'This field is required'
}) => {
  const inputBorder = isValid ? 'border-gray-200' : 'border-primary-default'
  const labelColor = isValid ? '' : 'text-primary-default'
  const errorClass = isValid ? 'hidden' : 'text-primary-default'
  return (
    <div className="w-full px-3">
      <p className={`block tracking-wide text-lg mb-2 font-display font-semibold ${labelColor}`}>
        {label}
      </p>
      <div className="flex flex-row">
        <input
          required
          className="block my-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="25,28,29 August 2023"
          name="time"
          type="radio"
          onClick={(e) => {
            onChange('time', '25,28,29 August 2023')
          }}
          checked={value === '25,28,29 August 2023'}
        />
        <label className="px-4 tracking-wide text-lg font-display" htmlFor="dec">
        25,28,29 August 2023
        </label>
      </div>
      <div className="flex flex-row">
        <input
          required
          className="block my-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="28,29.09, 02.10 2023"
          name="time"
          type="radio"
          onClick={(e) => {
            onChange('time', '28,29.09, 02.10 2023')
          }}
          checked={value === '28,29.09, 02.10 2023'}
        />
        <label className="px-4 tracking-wide text-lg font-display" htmlFor="dec">
        28,29.09, 02.10 2023
        </label>
      </div>
      <div className="flex flex-row">
        <input
          required
          className="block my-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="future"
          name="time"
          type="radio"
          onClick={(e) => {
            onChange('time', 'Other (to be consulted)')
          }}
        />
        <label className="px-4 tracking-wide text-lg font-display" htmlFor="future">
          Other (to be consulted)
        </label>
        <div className={errorClass}>{errorMessage}</div>
      </div>
    </div>
  )
}

export default RadioInput
